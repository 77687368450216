import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { mapActions, mapMutations } from "vuex";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Translations from "@/lib/Translations";
import PWA from "@/lib/PWA";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import config from "./config";
import "./polyfills";

// PWA
PWA.Init();

const app = createApp(App).use(store).use(router);
const version = process.env.VUE_APP_VERSION || "local";

app.mixin({
    methods: {
        ...mapActions("toasts", { $toast: "sendToast" }),
        ...mapMutations("loader", { $load: "setLoading" }),

        $t: Translations.Get,
    },
});

if (version !== "local") {
    Sentry.init({
        app,
        dsn: config.sentry.dsn,
        release: version,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: config.sentry.tracingOrigins,
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,
        beforeSend(event) {
            const breadcrumbs = event.breadcrumbs || [];
            const redirectCategory = breadcrumbs
                .slice(-10)
                .find((breadcrumbs) => {
                    return breadcrumbs.category === "401";
                });

            if (redirectCategory) {
                return null;
            }

            return event;
        },
    });
}

app.use(
    createGtm({
        id: config.gtm_id,
        enabled: true,
        compatibility: true,
        vueRouter: router,
    })
);

store.dispatch("user/getUserInfo");

app.mount("#app");
